<template lang="pug">
Overlay(unique-key='installerNet')
  p(class='font-bold text-center text-lg mb-2') {{ $geolocation.formattedLocationWithZip }}

  //- Tool bar for the storyblok editor
  div(v-if='$storyblok.isEditing.value' class='border-b mb-2 space-x-2 uppercase font-bold')
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": isSuccessful, "bg-white": !isSuccessful }'
      @click='isSuccessful = true'
    ) Success

    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": !isSuccessful, "bg-white": isSuccessful }'
      @click='isSuccessful = false'
    ) No Results

  div
    div(v-if='results.length > 0 || ($storyblok.isEditing.value && isSuccessful)')
      slot(name='success')

      div(class='pt-2' @click.stop)
        template(v-if='$storyblok.isEditing.value')
          div(class='flex justify-center items-center h-64 bg-gray-light')
            p Results placeholder
        template(v-else)
          table(class='w-full')
            tbody
              template(v-for='(result, index) in results' :key='index')
                tr(class='align-middle border-b border-gray-lighter last:border-0')
                  td(class='pl-5 py-3 whitespace-nowrap')
                    PinIcon(class='w-[13px] h-auto fill-current text-gray-light')
                  td(class='pl-5 py-3 whitespace-nowrap')
                    p(class='font-bold') {{ result.distance }} mi
                  td(class='pl-6 py-3 w-full')
                    p {{ result.city }}, {{ result.state }}

    div(v-else)
      slot(name='failure')
</template>

<script setup lang="ts">
import PinIcon from '@/assets/location.svg?component'

const { $storyblok, $geolocation } = useNuxtApp()

const { results } = defineProps<{
  results: InstallernetWidgetResults[]
}>()

const isSuccessful = ref(true)
</script>
